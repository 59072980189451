@import "/src/styles/variables";
@import "/src/styles/mixins";

.outerWrapper {
  background: radial-gradient(circle at 100% 100%, #feedee, #e8f5ff);
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 320px;
}

.wrapper {
  margin-top: 150px;
  display: flex;
  justify-content: space-between;
  max-width: $maxContentWidth;
  padding: 0px 20px;
}

.title {
  font-size: 52px;
  font-weight: 900;
  max-width: 700px;
  line-height: 57px;

  span {
    color: $blue;
  }
}

.subtitle {
  margin-top: 20px;
  font-size: 20px;
  font-weight: 400;
  max-width: 600px;
  line-height: 26px;
}

.btn {
  margin-top: 40px;
  border: none;
  background-color: $blue;
  color: white;
  width: 242px;
  height: 68px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 700;
  transition: all 0.3s ease;
  position: relative;
  z-index: 9990;
  &:hover {
    @include mainButtonHover;
  }
}

.topImgWrap {
  img {
    max-height: 482px;
    max-width: 451px;
  }
}

@media (max-width: 1000px) {
  .wrapper {
    flex-direction: column;
    align-items: center;
  }
  .topImgWrap {
    display: none;
  }
}

@media (max-width: $mobileScreen) {
  .title {
    font-size: 34px;
    line-height: 40px;
  }
  .btn {
    width: 100%;
  }
  .outerWrapper {
    padding-bottom: 220px;
  }
  .topImgWrap {
    display: none;
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}
