@import './../../../styles/variables';

.cardItem {
  box-shadow: 0px 0px 10px 0px $shadowColor;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 20px;
  transition: $defaultTransition;
  background-color: white;
  min-height: 165px;

  img {
    max-height: 120px;
    max-width: 120px;
  }

  p {
    text-align: center;
    font-weight: 500;
  }

  &:hover {
    transition: $defaultTransition;
    cursor: pointer;
    transform: scale(1.02);
  }
}
