@import "./../../styles/variables";
@import "./../../styles/mixins";

.outerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;
  padding-bottom: 100px;
  background-color: #f4f4f5;
  min-height: 100vh;
  box-sizing: border-box;
}

.wrapper {
  max-width: $maxContentWidth;
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.paginationWrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.elementWrapper {
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 0px 10px 0px $shadowColor;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

.formWrapper {
  width: 100%;
  overflow-x: auto;
}
