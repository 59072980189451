@import "/src/styles/variables";
@import "/src/styles/mixins";

.wrapper {
  margin-top: 80px;
  max-width: $maxContentWidth;
  width: 100%;
  padding: 0px 20px;
  box-sizing: border-box;
}

.header {
  display: flex;
  align-items: flex-end;
}

.title {
  display: initial;
  font-size: 72px;
  font-weight: 900;
  line-height: 64px;

  span {
    color: $blue;
  }
}

.subtitle {
  display: initial;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  max-width: 530px;
}

.footer {
  display: flex;
  margin-left: 50px;
  margin-top: 30px;

  p {
    font-size: 18px;
    font-weight: 900;
    color: $blue;
    margin-left: 5px;
  }
}

.content {
  margin-top: 90px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  .item {
    position: relative;
    background-color: $salesFunnelBgColor;
    padding: 20px;
    border-radius: 10px;

    &:nth-last-child(1) {
      grid-column: span 2;
    }

    &:nth-child(2) {
      div {
        img {
          width: 300px;
          margin-bottom: -5px;
        }
      }
      padding-bottom: 0px;
    }

    &:nth-child(3) {
      grid-row: span 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &:nth-child(4) {
      display: flex;
      padding-bottom: 0px;
      div {
        img {
          margin-bottom: -5px;
        }
      }
    }
  }
}

@media (max-width: 1150px) {
  .header {
    flex-direction: column;
  }
  .title {
    margin-bottom: 20px;
  }
  .subtitle {
    max-width: 100%;
  }
  .content {
    display: flex;
    flex-direction: column;
  }
  .item {
    &:nth-child(4) {
      flex-direction: column;
    }
  }
}

@media (max-width: $mobileScreen) {
  .title {
    display: none;
  }
  .subtitle {
    display: none;
  }
  .wrapper {
    margin-top: 0px;
    margin-bottom: 50px;
  }
  .content {
    margin-top: 20px;
  }
  .footer {
    margin-top: 0px;
  }
  .item {
    div {
      img {
        margin: 0px;
        margin-top: 20px;
        width: 100% !important;
      }
    }
    &:nth-child(3) {
      padding-bottom: 0px;
    }
  }
}
