@import "/src/styles/variables";
@import "/src/styles/mixins";

.wrapper {
  max-width: $maxContentWidth;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

@media (max-width: $mobileScreen) {
  .wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }
}
