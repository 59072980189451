@import "./../../styles/variables";

.wrapper {
  width: 100%;
  box-sizing: border-box;
  border: 1px dashed grey;
  color: grey;
  box-shadow: 3px 3px 10px 0px $shadowColor;
  height: 50px;
  border-radius: $defaultBorderRadius;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
