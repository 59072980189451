@import "./../../../styles/variables";

.wrapper {
  padding: 0px 20px;
  max-width: 400px;
}

.questionTitle {
  font-size: 24px;
  font-weight: 700;
  margin: 20px 0px;
}

.nameSection {
  display: flex;
  column-gap: 10px;
}

.inputLabel {
  color: grey;
  text-align: start;
  font-size: 12px;
}

.otherInfo {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  input[type="date"] {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  input[type="time"] {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

.errorMessage {
  text-align: start;
  margin-bottom: 10px;
  color: $red;
}

.phoneNumberInputWrapper {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid $blue;
  box-shadow: 3px 3px 10px 0px $shadowColor;
  height: 45px;
  padding: 0px 15px;
  border-radius: $defaultBorderRadius;
  background-color: white;

  input {
    border: none;
    font-size: 16px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: rgb(171, 171, 171);
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

@media (max-width: 400px) {
  .wrapper {
    margin-bottom: 100%;
  }
}
