@import "/src/styles/mixins";
@import "/src/styles/variables";

.wrapper {
  border: 1px solid #b7eb8f;
  background-color: #f4fdeb;
  border-radius: 5px;
  padding: 8px 12px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.textWrap {
  display: flex;
  align-items: center;

  img {
    margin-right: 5px;
  }
}

.buttonsWrap {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.actionBtn {
  border: 1px solid $grey;
  background-color: white;
  border-radius: 5px;
  padding: 6px 10px;
  cursor: pointer;
  transition: $defaultTransition;
  color: black;
  text-decoration: none;

  &:hover {
    border-color: #419600;
    color: #419600;
    transition: $defaultTransition;
  }
}

.closeBtn {
  background-color: #f4fdeb;
  border: none;
  cursor: pointer;
  padding: 0px;
  height: 20px;
  width: 20px;
}

@media (max-width: $mobileScreen) {
  .wrapper {
    flex-direction: column;
    row-gap: 10px;
    text-align: center;
  }
  .textWrap {
    flex-direction: column;
    row-gap: 10px;
  }
  .closeBtn {
    position: absolute;
    right: 0;
    top: 0;
    height: 30px;
    width: 30px;
    border-radius: 5px;
  }
}
