@import "./../../../styles/variables";
@import "./../../../styles/mixins";

.wrapper {
  min-width: 300px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
}

.inputs {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  input {
    @include inputAuth;
  }
}

.btnWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.submitBtn {
  border: none;
  background-color: $blue;
  color: white;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 700;
  transition: all 0.3s ease;
  &:hover {
    @include mainButtonHover;
  }
}

.inputError {
  border-color: $red !important;
}

.errorMessage {
  color: $red;
  font-size: 12px;
  margin-bottom: 10px;
}

.successMessage {
  color: green;
  font-size: 12px;
  margin-bottom: 10px;
}

@media (max-width: $mobileScreen) {
  .submitBtn {
    width: 100%;
  }
}

@media (max-width: 350px) {
  .wrapper {
    min-width: 100%;
    margin: 0px 10px;
    box-sizing: border-box;
  }
}
