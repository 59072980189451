@import "/src/styles/mixins";
@import "/src/styles/variables";

.content {
  padding: 20px 10px 10px 10px;
  max-width: 400px;
  text-align: center;
}

.title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.inputsWrap {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.phoneInput {
  border: 1px solid $grey;
  border-radius: 5px;
  padding: 0px 15px;
  box-sizing: border-box;

  input {
    height: 50px;
    border: none;
    box-sizing: border-box;
    padding: 10px;

    &:focus {
      outline: none;
    }
  }
}

.btnWrap {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button {
    border: none;
    background-color: $blue;
    color: white;
    width: 100px;
    height: 40px;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 700;
    transition: all 0.3s ease;
    &:hover {
      @include mainButtonHover;
    }
  }
}

.errorMessage {
  color: $red;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
}

@media (max-width: $mobileScreen) {
  .btnWrap {
    button {
      width: 100%;
    }
  }
}
