@import "/src/styles/variables";
@import "/src/styles/mixins";

.wrapper {
  margin-top: 100px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 52px;
    font-weight: 900;
  }

  button {
    margin-top: 50px;
    border: none;
    background-color: $blue;
    color: white;
    width: 300px;
    height: 70px;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 700;
    transition: all 0.3s ease;
    &:hover {
      @include mainButtonHover;
    }
  }
}

.divider {
  border-bottom: 4px solid $blue;
  width: 100%;
  max-width: 56px;
  border-radius: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 24px;
  max-width: 1150px;
}

@media (max-width: $mobileScreen) {
  .wrapper {
    h3 {
      font-size: 34px;
    }
  }
}
