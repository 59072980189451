@import "./../../../../../styles/variables";
@import "./../../../../../styles/mixins";

.title {
  font-size: 20px;
  font-weight: 700;
  margin: 20px 0px 0px 0px;
  text-align: center;
  max-width: 520px;
  word-wrap: break-word;
  word-break: break-all;

  span {
    font-weight: normal;
  }
}

.multipleSelectToggleWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;

  p {
    font-weight: normal;
    font-size: 14px;
  }
}

.answerOptionsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  max-height: 300px;
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;

  @include customScrollBar;
}

.btnWrap {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button {
    border: none;
    background-color: $blue;
    color: white;
    width: 100px;
    height: 40px;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 700;
    transition: all 0.3s ease;
    &:hover {
      @include mainButtonHover;
    }
  }
}

@media (max-height: 670px) {
  .answerOptionsWrapper {
    max-height: 40vh;
  }
}

@media (max-width: $mobileScreen) {
  .answerOptionsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
