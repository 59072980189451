@import "./../../styles/variables";
@import "./../../styles/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.innerWrapper {
  position: relative;
}

.searchWrapper {
  display: flex;

  button {
    height: 20px;
    background-color: $blue;
    color: white;
    border: none;
    border-radius: 0px 3px 3px 0px;
    cursor: pointer;
  }
}

.iconsWrapper {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  height: auto;
  max-height: 250px;
  width: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1000;
  padding: 20px;

  input {
    width: 100%;
    margin-bottom: 10px;
    border: 1px solid black;
    border-radius: 3px 0px 0px 3px;
  }

  @include customScrollBar;
}

.iconsSelectWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}

.iconsSelect {
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: rgb(241, 241, 241);
  }
}

.iconsButton {
  background-color: white;
  border: none;
  cursor: pointer;

  img {
    margin-bottom: 10px;
  }
}
