@import "./../../../styles/variables";
@import "./../../../styles/mixins";

.wrapper {
  display: flex;
  margin-top: 200px;
  margin-bottom: 20px;
  justify-content: space-between;
  width: 100%;
}

.contentBlock {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  max-width: 220px;
  row-gap: 10px;
}

.getStarted {
  border: none;
  background-color: $blue;
  color: white;
  width: 200px;
  height: 50px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 700;
  transition: all 0.3s ease;
  &:hover {
    @include mainButtonHover;
  }
}

.content {
  display: flex;
  gap: 50px;
}

.navigation {
  padding: 0px;

  li {
    font-weight: normal;
    font-weight: 600;
    list-style: none;

    a {
      color: black;
      text-decoration: none;

      &:hover {
        color: $navHover;
      }
    }
  }
}

.emailLink {
  text-decoration: none;
  color: black;
  &:hover {
    color: $navHover;
  }
}

@media (max-width: $mobileScreen) {
  .wrapper {
    margin-top: 100px;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
  }

  .content {
    flex-direction: column;
  }

  .socials {
    flex-direction: row;
    column-gap: 20px;
  }

  .copyrightContent {
    padding: 0 15px;
    max-width: 220px;
  }
}

.copyright {
  display: flex;
  justify-content: center;
  text-align: center;   
  margin-bottom: 20px;
  a {
    text-decoration: none;
    color: black;
    &:hover {
      color: $navHover;
    }
  }
}
