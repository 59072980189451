@import "./../../../styles/variables";
@import "./../../../styles/mixins";

.wrapper {
  box-sizing: border-box;
  max-width: 500px;
}

.title {
  font-size: 20px;
  font-weight: 600;
}

.errorTitle {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding: 50px 0px;

  .link {
    color: $blue;
    text-decoration: underline;
  }
}

.inputWrapper {
  display: flex;
  align-items: center;
  column-gap: 5px;

  input {
    border: 1px solid grey;
    height: 20px;
    width: 100%;
    margin-top: 20px;
    padding: 2px 5px;

    &:focus {
      outline: none;
    }
  }

  p {
    margin-top: 18px;
  }
}

.btnWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  button {
    border: none;
    background-color: $blue;
    color: white;
    width: 100px;
    height: 30px;
    border-radius: 5px;
    transition: all 0.3s ease;
    &:hover {
      @include mainButtonHover;
    }
  }
}

.errorMessage {
  max-width: 280px;
  color: $red;
  font-size: 14px;
  margin-bottom: 5px;
}

.businessProfileSelectWrap {
  margin-top: 10px;

  p {
    font-size: 14px;
  }
}

.select {
  height: 27px;
  background-color: white;
  color: black;
  border-radius: 0px;

  &:focus {
    outline: none;
  }
}

.publishSuccess {
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .titleSuccess {
    color: $blue;
    font-size: 20px;
    text-align: center;
  }

  .description {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  a {
    color: $blue;
  }

  button {
    border: none;
    background-color: $blue;
    color: white;
    width: 100px;
    height: 30px;
    border-radius: 5px;
    transition: all 0.3s ease;
    &:hover {
      @include mainButtonHover;
    }
  }
}
