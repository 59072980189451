.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 9998;
  padding: 0px 10px;
}

.showOverlay {
  opacity: 1;
  visibility: visible;
}

.hideOverlay {
  opacity: 0;
  visibility: hidden;
}

.modal {
  box-sizing: border-box;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-50px);
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.showModal {
  transform: translateY(0);
  opacity: 1;
}

.hideModal {
  transform: translateY(-50px);
  opacity: 0;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}
