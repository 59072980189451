@import "./../../styles/variables";
@import "./../../styles/mixins";

.wrapper {
  max-width: 400px;
}

.title {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 5px;
}

.subtitle {
  font-size: 14px;
  line-height: 20px;
}

.buttonsWrap {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  column-gap: 5px;
}

.cancelButton {
  border: 1px solid rgb(185, 185, 185);
  background-color: white;
  color: black;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  padding: 10px;
}

.actionButton {
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  padding: 10px;
}
