@import "/src/styles/variables";

.downloadsWrapper {
  display: flex;
}

.downlaodButton {
  background-color: white;
  border: none;
  padding: 0px;
  cursor: pointer;
}

svg {
  fill: #000;
  transition: fill 0.3s ease;
}

svg:hover {
  fill: $blue;
}
