@import "/src/styles/variables";

@mixin sliderDefault {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.2s;
  border-radius: 34px;
}

@mixin sliderBefore {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  margin-top: -4px;
  transition: 0.2s;
  border-radius: 50%;
}

.toggle-switch {
  position: relative;
  width: 40px;
  height: 14px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  @include sliderDefault;
}

.slider:before {
  @include sliderBefore;
  background-color: #2466c5;
}

.disabledSlider {
  @include sliderDefault;
}

.disabledSlider:before {
  @include sliderBefore;
  background-color: $grey;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(20px);
}
