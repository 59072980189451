.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
  padding: 0px 10px;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: auto;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modalClose {
  position: absolute;
  top: 5px;
  right: 0px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  z-index: 1;
  background-color: white;

  img {
    height: 30px;
    width: 30px;
  }
}
