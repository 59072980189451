@import "./../../styles/variables";
@import "./../../styles/mixins";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  textarea {
    font-family: "DM Sans", sans-serif;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid $blue;
    box-shadow: 3px 3px 10px 0px $shadowColor;
    height: 50px;
    padding: 15px;
    border-radius: $defaultBorderRadius;
    font-size: 16px;
    resize: vertical;
    overflow: hidden;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: rgb(171, 171, 171);
    }
  }
}

.inputLabel {
  position: absolute;
  @include inputLabel;
  color: $grey;
  margin-top: -10px;
  margin-left: 15px;
  padding: 0px 5px;
  border-radius: 5px;
  background-color: white;
  max-width: fit-content;
}
