@import "./../../../../styles/variables";
@import "./../../../../styles/mixins";

.outerWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  width: 100%;
  text-align: center;

  h4 {
    margin: 20px 0px;
    font-size: 32px;
  }
}

.button {
  border: none;
  background-color: $blue;
  color: white;
  width: 300px;
  height: 70px;
  border-radius: 5px;
  font-size: 17px;
  transition: all 0.3s ease;
  &:hover {
    @include mainButtonHover;
  }
}

.formSavedLabel {
  margin-top: 10px;
  color: green;
}

.formSaveErrorLabel {
  margin-top: 10px;
  color: $red;
}
