@import "/src/styles/variables";
@import "/src/styles/mixins";

.wrapper {
  border: 2px solid $grey;
  border-radius: 5px;
  padding: 5px 5px 50px 15px;
  width: 180px;
  height: 180px;
  box-sizing: border-box;

  p {
    font-size: 14px;
  }
}

.buttonsWrap {
  display: flex;
  column-gap: 5px;
  justify-content: flex-end;

  button {
    @include actionButton;
  }
}

.label {
  color: grey;
}

.value {
  font-weight: 600;
  color: black;
  margin-bottom: 10px;
}
