@import "./../../styles/variables";

.fileUploaderWrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%;
}

.fileUploaderButton {
  border: none;
  background-color: $blue;
  color: white;
  border-radius: $defaultBorderRadius;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  padding: 30px;

  p {
    margin: 15px 0px;
  }
}

.fileUploaderInput {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
