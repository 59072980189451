@import "/src/styles/variables";

.wrapper {
  text-align: center;
  padding: 0px 20px;
  box-sizing: border-box;

  h4 {
    font-size: 48px;
    margin-top: 150px;
    margin-bottom: 10px;

    span {
      color: $blue;
    }
  }
}

.subtitle {
  font-size: 20px;
  font-weight: 400;
}

.imgWrap {
  background-color: $salesFunnelBgColor;
  border-radius: 12px;
  padding: 24px;
  max-width: 500px;
  width: 100%;
}

.sliderWrapper {
  margin-top: 40px;
  display: flex;

  ul {
    padding-left: 20px;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  li {
    box-sizing: border-box;
    padding: 20px;
    min-height: 145px;
    cursor: pointer;
    background-color: $salesFunnelBgColor;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  img {
    max-width: 450px;
    max-height: 400px;
    width: 100%;
    height: 100%;
  }
}

.listText {
  text-align: start;
}

.listNumber {
  background-color: $blue;
  border-radius: 5px;
  min-height: 30px;
  min-width: 30px;
  max-height: 30px;
  max-width: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  margin-right: 10px;
}

.listTitle {
  font-size: 18px;
  font-weight: 700;
  margin-top: 0px;
}

.listSubtitle {
  font-size: 14px;
  margin: 0px;
}

.selectedList {
  background-color: $blue !important;
  color: white;
  border-radius: 12px;
}

.selectedListNumber {
  background-color: white;
  color: $blue;
}

@media (max-width: $mobileScreen) {
  .sliderWrapper {
    flex-direction: column;
    align-items: center;
  }
}
