@import "./../../../../styles/variables";

.wrapper {
  max-width: 420px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .btn {
    margin-top: 15px;
    width: 100%;
    height: 50px;
    border: none;
    background-color: $blue;
    color: white;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 700;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
      background-color: $buttonHoverColor;
      transition: all 0.3s ease;
    }
  }
}

.inputsWrapper {
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}

.errorInput {
  border: 1px solid rgb(234, 0, 0) !important;
}

.alternativeSignUpVariants {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 15px;
  .googleLogin {
    background-color: white;
    border: 1px solid black;
    padding: 10px;
    height: 60px;
    font-weight: 700;
    font-size: 16px;
    color: rgb(70, 70, 70);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 0px;
    margin-bottom: 10px;
  }
}

.labelOr {
  margin: 10px 0px;
}

.errorMessage {
  color: rgb(234, 0, 0);
}

.linksWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 350px;
  margin-top: 10px;

  a {
    text-decoration: none;
    color: $blue;
    font-weight: 500;
  }
}

.signUpBtn {
  background-color: white;
  border: none;
  font-size: 14px;
  color: $blue;
  font-weight: 600;
  cursor: pointer;
}
