@import "./../../../../styles/mixins";
@import "./../../../../styles/variables";

.wrapper {
  @include calendlyModalWrapper;
  overflow: hidden;
  min-width: 600px;
  pointer-events: auto;
  touch-action: auto;
}

.content {
  box-sizing: border-box;
}

.calendlyWrapper {
  min-width: 320px;
}

.loaderWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
}

@media (max-height: 1366px) {
  .wrapper {
    min-width: 800px;
    max-width: 800px;
    max-height: 95vh;
    min-height: 80vh;
  }

  .calendlyWrapper {
    min-height: 80vh;
  }
}

@media (max-height: 667px) {
  .wrapper {
    max-height: 95vh;
    min-height: 95vh;
  }

  .calendlyWrapper {
    min-height: 95vh;
  }
}

@media (max-width: $mobileScreen) {
  .wrapper {
    @include calendlyModalWrapperMobile;
  }
}
