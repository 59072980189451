@import "./../../../../../../styles/variables";

.wrapper {
  background-color: white;
  border: 2px dashed grey;
  min-height: 150px;
  width: 150px;
  border-radius: 10px;
  box-shadow: 3px 3px 10px 0px $shadowColor;
  cursor: pointer;
}
