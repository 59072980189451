@import "/src/styles/variables";
@import "/src/styles/mixins";

.content {
  padding: 20px 10px 10px 10px;
  max-width: 400px;
  text-align: center;
}

.title {
  font-size: 24px;
  font-weight: 600;
}

.qrCodeWrapper {
  margin-top: 30px;
}

.btnWrap {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  button {
    border: none;
    background-color: red;
    color: white;
    width: 180px;
    height: 50px;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 700;
    transition: all 0.3s ease;
    &:hover {
      @include mainButtonHover;
    }
  }
}
