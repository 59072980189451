@import "./../../styles/variables";
@import "./../../styles/mixins";

.outerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background-color: #f4f4f5;
  padding: 0px 20px;
  box-sizing: border-box;
}

.alertWrapper {
  margin-bottom: 30px;
}

.wrapper {
  max-width: $maxContentWidth;
  width: 100%;
  margin-top: 100px;
}

.newFormButton {
  border: none;
  background-color: $blue;
  color: white;
  width: 170px;
  height: 170px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 700;
  transition: $defaultTransition;

  p {
    transition: $defaultTransition;
  }

  &:hover {
    @include mainButtonHover;

    p {
      transform: scale(1.1);
      transition: $defaultTransition;
    }
  }
}

.formsWrapper {
  margin-bottom: 50px;
  display: flex;
  gap: 28px;
  flex-wrap: wrap;

  a {
    text-decoration: none;
  }
}

@media (max-width: $mobileScreen) {
  .formsWrapper {
    justify-content: center;
  }
}
