@import "/src/styles/mixins";
@import "/src/styles/variables";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 20px;
}

.content {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  max-height: 350px;
  padding-bottom: 5px;
  overflow-y: auto;

  @include customScrollBar;
}

.inputsWrap {
  display: flex;
  width: 100%;
  max-width: 265px;
  flex-direction: column;
  row-gap: 10px;
  border: 2px solid $grey;
  padding: 15px;
  margin-right: 10px;
  box-sizing: border-box;
  border-radius: 5px;
}

.btnWrap {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 20px;
}

.businessLabel {
  font-size: 14px;
  color: grey;
  word-wrap: break-word;
  word-break: break-all;

  span {
    color: black;
    font-weight: 600;
  }
}

.buttonsWrap {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  column-gap: 5px;
  margin-top: -10px;
  margin-left: 10px;
}

.actionBtn {
  @include actionButton;
}

.addBtn {
  border: none;
  background-color: $blue;
  color: white;
  width: 265px;
  height: 40px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  transition: all 0.3s ease;
  margin-right: 15px;
  &:hover {
    @include mainButtonHover;
  }
}

@media (max-width: $mobileScreen) {
  .saveBtn {
    width: 100%;
  }
}
