@import "/src/styles/variables";
@import "/src/styles/mixins";

.wrapper {
  border: 1px solid $grey;
  width: 200px;
  min-height: 180px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    margin-top: 5px;
    font-weight: 600;
  }

  span {
    font-weight: normal;
    word-wrap: break-word;
    white-space: normal;
  }

  button {
    margin-top: 20px;
    border: none;
    color: white;
    background-color: $red;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    padding: 10px;
    width: 100%;
    transition: $defaultTransition;

    &:hover {
      transition: $defaultTransition;
      background-color: $buttonHoverColor;
    }
  }

  .endedLabel {
    text-align: center;
    border: 2px solid black;
    border-radius: 5px;
    padding: 8px;
    font-weight: 600;
  }
}
