.wrapper {
  width: 100%;
  display: flex;
  column-gap: 160px;
  border-bottom: 1px solid grey;
  padding: 10px 0px;
  min-width: 950px;
}

.questionsLabel {
  margin-left: 80px;
}

@media (max-width: 1000px) {
  .wrapper {
    column-gap: 15px;
  }
  .questionsLabel {
    margin-left: 70px;
  }
}
