.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.period {
  margin-top: 10px;

  span {
    text-decoration: underline;
  }
}
