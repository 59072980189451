@import "/src/styles/variables";
@import "/src/styles/mixins";

.outerWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.wrapper {
  max-width: $maxContentWidth;
  width: 100%;
  padding: 0px 20px;
  box-sizing: border-box;
}

.topOuterBg {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5fbff;
}

.footerWrapper {
  padding: 0px 20px;
}

.integrates,
.actionContentFooter,
.howItWorks,
.benefits {
  display: initial;
}

@media (max-width: $mobileScreen) {
  .integrates,
  .actionContentFooter,
  .howItWorks,
  .benefits {
    display: none;
  }
}
