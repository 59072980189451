@import "/src/styles/variables";

.wrapper {
  margin-top: 80px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(231, 244, 254);
  background: linear-gradient(
    33deg,
    rgba(231, 244, 254, 1) 65%,
    rgba(254, 243, 244, 1) 100%,
    rgba(0, 212, 255, 1) 100%
  );
  padding: 30px;
  min-height: 220px;

  .title {
    font-size: 48px;
    max-width: 500px;
  }
}

.sliderWrapper {
  max-width: 450px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 30px;
  min-height: 220px;

  p {
    font-size: 20px;
  }
}

.navigationButtons {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-left: 20px;

  button {
    background-color: white;
    border: none;
    border-radius: 100px;
    height: 40px;
    width: 40px;
    cursor: pointer;

    &:nth-child(2) {
      img {
        transform: rotate(180deg);
      }
    }
  }
}

.contentWrapper {
  display: flex;
}

@media (max-width: 1100px) {
  .wrapper {
    flex-direction: column;

    .title {
      margin-bottom: 20px;
      font-size: 38px;
      line-height: 40px;
    }
  }
}

@media (max-width: $mobileScreen) {
  .sliderWrapper {
    min-height: 400px;
  }
}
