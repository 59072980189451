@import "/src/styles/variables";
@import "/src/styles/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 100px;
}

.title {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 24px;
}

.imgWrap {
  width: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.itemsWrap {
  margin-top: 20px;
  width: 100%;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  max-width: 100%;
}

.header {
  display: flex;
  column-gap: 10px;
}

.label {
  font-size: 14px;
  color: grey;
}

.formSelect {
  height: 50px;
  width: 120px;
  cursor: pointer;
  color: black;
  border-radius: 0px;
  background-color: white;
  padding: 0px 5px;

  &:focus {
    outline: none;
  }
}

.addBtn {
  width: 180px;
  height: 180px;
  background-color: white;
  border: 2px solid $blue;
  border-radius: 5px;
  color: $blue;
  font-weight: 600;
  padding: 15px;
  font-size: 17px;
  cursor: pointer;
}

.saveBtn {
  border: none;
  background-color: $blue;
  color: white;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 700;
  transition: all 0.3s ease;
  margin-top: 24px;

  &:hover {
    @include mainButtonHover;
  }
}

.removeBtn {
  border: 2px solid $red;
  background-color: white;
  color: $red;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 700;
  transition: all 0.3s ease;
  margin-top: 24px;

  &:hover {
    @include mainButtonHover;
    color: white;
    border-color: white;
  }
}

.disabledBtn {
  color: grey !important;
}

.savedMessage {
  font-size: 14px;
  color: green;
  text-align: center;
}

@media (max-width: $mobileScreen) {
  .header {
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
  }
  .itemsWrap {
    justify-content: center;
  }
}
