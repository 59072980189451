@import "./../../../styles/variables";
@import "./../../../styles/mixins";

.outerWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  box-sizing: border-box;
}

.wrapper {
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 0px 10px 0px $shadowColor;
  border-radius: 5px;
  width: 100%;
  max-width: $maxContentWidth;
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.titleWrap {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  img {
    margin-right: 10px;
  }
}

.title {
  font-weight: 600;
  font-size: 18px;
  color: $grey;
}

.content {
  display: flex;
  flex-direction: column;
  column-gap: 20px;
  width: 100%;
}

.emailsWrapper {
  margin-top: 10px;
  display: flex;
  gap: 20px;
  width: 100%;

  p {
    color: grey;
    font-size: 12px;
  }
}

.emailsWrap {
  display: flex;
  flex-direction: column;
  width: 100%;

  ul {
    box-sizing: border-box;
    padding: 18px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    max-width: 290px;
    border: 1px solid $grey;
    border-radius: 5px;

    li {
      font-size: 14px;
      list-style: none;
      border: 1px solid $grey;
      padding: 5px;
      display: flex;
      align-items: center;

      img {
        cursor: pointer;
      }
    }
  }
}

.addEmailWrap {
  width: 100%;

  input {
    height: 25px;
    border: 1px solid $grey;
    box-sizing: border-box;
    width: 100%;
    padding: 0px 10px;

    &:focus {
      outline: none;
    }
  }
}

.btn {
  background-color: $blue;
  border: none;
  border-radius: 0px;
  color: white;
  height: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 50px;

  &:hover {
    @include mainButtonHover;
  }
}

.error {
  font-size: 12px;
  color: $red;
}

.inputEmail {
  display: flex;
  align-items: center;
  width: 100%;
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  margin-top: 30px;
  column-gap: 10px;

  input {
    height: 15px;
    width: 15px;
    margin-top: 7px;
    cursor: pointer;
  }

  p {
    color: grey;
    font-size: 12px;
  }
}

.selectWrapper {
  border: 1px solid $grey;
  height: 47px;
  border-radius: 5px;
  width: 200px;
  padding-left: 5px;
  background-color: white;
  color: black;

  &:focus {
    outline: none;
  }
}

.subjectWrapper {
  width: 100%;

  input {
    height: 47px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 0px 10px;
    width: 100%;
    max-width: 510px;
    border: 1px solid $grey;

    &:focus {
      outline: none;
    }
  }

  p {
    color: grey;
    font-size: 12px;
  }
}

.saveBtnWrapper {
  width: 100%;
  display: flex;
  margin-top: 30px;
  flex-direction: column;

  button {
    border: none;
    background-color: $blue;
    color: white;
    width: 100px;
    height: 40px;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 700;
    transition: all 0.3s ease;
    &:hover {
      @include mainButtonHover;
    }
  }

  p {
    color: green;
    margin-left: 30px;
  }
}

.emailFrom {
  input {
    color: grey;
  }
}

.leftContent {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

@media (max-width: $mobileScreen) {
  .emailsWrapper {
    flex-direction: column;
  }
  .subjectWrapper {
    input {
      max-width: 100%;
    }
  }
  .selectWrapper {
    width: 100%;
  }
  .emailsWrap {
    ul {
      max-width: 100%;
    }
  }
  .emailFrom {
    input {
      max-width: 100%;
    }
  }
}
