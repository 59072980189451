@import "./../../styles/variables";
@import "./../../styles/mixins";

.outerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;
  margin-bottom: 50px;
}

.wrapper {
  max-width: $maxContentWidth;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.sectionWrapper {
  margin-top: 150px;
  margin-bottom: 50px;
}

.title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
}

.checkbox {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.button {
  box-sizing: border-box;
  border: 1px dashed grey;
  color: grey;
  background-color: rgb(245, 245, 245);
  min-height: 375px;
  height: 100%;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  p {
    transition: $defaultTransition;
  }

  &:hover {
    p {
      transition: $defaultTransition;
      transform: scale(1.1);
    }
  }
}

.paginationWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.elementsWrap {
  margin-top: 50px;
}

.searchWrap {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  font-size: 14px;
}

.checkboxWrap {
  font-size: 14px;

  input {
    height: 20px;
    width: 20px;
    cursor: pointer;
    margin: 0px;
  }
}

@media (max-width: $mobileScreen) {
  .wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }
  .button {
    min-height: 200px;
  }
  .searchWrap {
    flex-direction: column;
  }
}
