@import "./../../../styles/variables";
@import "./../../../styles/mixins";

.outerWrapper {
  display: flex;
  justify-content: center;
  padding: 0px 20px;
}

.wrapper {
  max-width: 950px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h6 {
    font-size: 48px;
    max-width: 500px;
  }

  .button {
    button {
      border: none;
      background-color: $blue;
      color: white;
      width: 300px;
      height: 70px;
      border-radius: 5px;
      font-size: 17px;
      font-weight: 700;
      transition: all 0.3s ease;
      &:hover {
        @include mainButtonHover;
      }
    }
  }
}

@media (max-width: $mobileScreen) {
  .wrapper {
    flex-direction: column;
    row-gap: 20px;

    h6 {
      font-size: 38px;
    }

    .button {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
}
