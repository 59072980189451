.wrapper {
  width: 100%;
  display: flex;
  column-gap: 200px;
  border-bottom: 1px solid grey;
  padding: 10px 0px;
  min-width: 950px;
  overflow-x: auto;

  p {
    word-wrap: break-word;
    white-space: normal;
    max-width: 120px;
  }
}

.questionAnswerItem {
  display: flex;
  column-gap: 10px;
  font-size: 14px;

  p,
  strong {
    word-wrap: break-word;
    white-space: normal;
    max-width: 150px;
  }
}

.userName {
  width: 110px;
}

@media (max-width: 1000px) {
  .wrapper {
    column-gap: 50px;
  }
}
