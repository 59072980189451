@import "/src/styles/variables";
@import "/src/styles/mixins";

.paymentMethodsWrap {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 210px;
  overflow-y: auto;

  @include customScrollBar;
}

.title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 20px;
}

.addPaymentMethodBtn {
  border: none;
  background-color: $blue;
  color: white;
  margin-top: 10px;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  transition: all 0.3s ease;
  &:hover {
    @include mainButtonHover;
  }
}
