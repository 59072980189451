@import "/src/styles/variables";
@import "/src/styles/mixins";


.wrapper {
  border: 2px solid $grey;
  border-radius: 5px;
  padding: 7px 10px;
}

.default {
  border: 2px solid $blue;
}

.btn {
  margin-top: 10px;
  border: 1px solid $blue;
  background-color: white;
  color: $blue;
  width: fit-content;
  height: 25px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.defaultLabel {
  border: 1px solid grey;
  border-radius: 5px;
  font-size: 14px;
  color: grey;
  padding: 2px 5px;
  max-width: fit-content;
  margin-top: 10px;
}
