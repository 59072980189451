@import "./../../../../styles/variables";

.wrapper {
  max-width: 420px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .btn {
    margin-top: 15px;
    width: 100%;
    height: 50px;
    border: none;
    background-color: $blue;
    color: white;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 700;
    transition: all 0.3s ease;
    &:hover {
      cursor: pointer;
      background-color: $buttonHoverColor;
      transition: all 0.3s ease;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.inputsWrapper {
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}

.errorInput {
  border: 1px solid rgb(234, 0, 0) !important;
}

.alternativeSignUpVariants {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 15px;
  .button {
    background-color: white;
    border: 1px solid black;
    padding: 10px;
    height: 60px;
    font-weight: 700;
    font-size: 16px;
    color: rgb(70, 70, 70);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    padding: 0px;

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.labelOr {
  margin: 10px 0px;
  text-align: center;
}

.errorMessage {
  color: rgb(234, 0, 0);
}

.successMessage {
  color: green;
}

.termsWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  a {
    text-decoration: none;
    color: $blue;
  }
  .checkboxWrapper {
    margin: 5px 0;
  }
}
