@import "./../../styles/variables";
@import "./../../styles/mixins";

.outerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper {
  max-width: 768px;
  width: 100%;

  strong {
    font-weight: 700;
  }

  h3 {
    text-align: center;
    margin: 20px 0;
  }

  p {
    text-align: center;
    margin: 15px 0;
  }

  a {
    text-decoration: none;
    color: $blue;
  }

  .privacyPolicy {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  ul {
    margin: 0 20px;
  }

  ul li p {
    text-align: start;
  }
}

.lastUpdated {
  text-align: center;
  margin: 15px 0;
}

@media (max-width: $mobileScreen) {
  .outerWrapper {
    margin: 10px 20px;
  }
}
