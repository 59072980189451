@import "/src/styles/variables";

.gradient {
  background: linear-gradient(to top, white, transparent);
  height: 200px;
  margin-top: -200px;
}

.wrapper {
  display: flex;
  justify-content: center;
  padding: 0px 20px;
  box-sizing: border-box;
}

.content {
  max-width: $maxContentWidth;
  width: 100%;
}

.contentWrap {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 50px;
}
