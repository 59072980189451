@import "./../../../styles/variables";

.wrap {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: white;
  position: fixed;
  z-index: 9991;
}

.outerWrapper {
  max-width: 1400px;
  width: 100%;
  background-color: white;

  a {
    text-decoration: none;
  }

  .navItem {
    padding: 5px;
    color: black;
    transition: all 0.3s ease;

    &:hover {
      color: $navHover;
      cursor: pointer;
      transition: all 0.3s ease;
    }
  }
}

.dropDown {
  display: flex;

  img {
    margin-top: 5px;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  padding: 0px 20px;
}

.controlPanel {
  display: flex;
  column-gap: 20px;
  align-items: center;
}

.buttons {
  display: initial;
}

.navigation {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  column-gap: 20px;
  font-weight: 700;
  font-size: 14px;

  .selected {
    margin-top: 5px;
    border-bottom: 2px solid black;
    padding-bottom: 5px;
  }
}

.button {
  border: none;
  background-color: $blue;
  color: white;
  width: 180px;
  height: 50px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 700;
  transition: all 0.3s ease;
  &:hover {
    cursor: pointer;
    background-color: $buttonHoverColor;
    transition: all 0.3s ease;
  }
}

.mobileListWrapper {
  display: none;
}

.hamburger {
  display: none;
}

.login {
  margin-right: 20px;
  font-weight: 600;
  color: black;
  background-color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 0px;
}

.mobileLoginBtn {
  padding: 0px;
  background-color: white;
  border: none;
  cursor: pointer;
  font-size: 15px;
}

.selectedPopup {
  background-color: #f0f0f0;
}

.closeBtn {
  display: none;
}

.whoWeServeListWrapper {
  display: flex;
  position: fixed;
  height: auto;
  width: 100%;
  max-width: 500px;
  margin-top: 72px;
  box-shadow: 0px 4px 5px 0px $shadowColor;
  background-color: white;
  border-right: 1px solid rgb(236, 236, 236);
  border-left: 1px solid rgb(236, 236, 236);
  border-bottom: 1px solid rgb(236, 236, 236);
  justify-content: center;
  z-index: 9999;
}

@media (max-width: 1250px) {
  .controlPanel {
    display: none;
  }
  .buttons {
    display: none;
  }
  .hamburger {
    display: initial;
    div {
      height: 5px;
      width: 35px;
      border-bottom: 4px solid black;
    }
  }
  .mobileListWrapper {
    padding: 0px;
    display: initial;
    position: absolute;
    height: auto;
    width: 100vw;
    background-color: white;
    z-index: 9991;

    li {
      border-bottom: 1px solid rgb(215, 215, 215);
      padding: 10px 20px;

      a {
        color: black;
      }
    }

    li:last-child {
      a {
        color: rgb(30, 155, 255);
      }
      button {
        border: none;
        background-color: white;
        padding: 0px;
        font-size: 16px;
        color: rgb(30, 155, 255);
      }
    }
  }
  .closeBtn {
    display: initial;
    font-size: 50px;
  }
}
