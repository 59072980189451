@import '/src/styles/variables';
@import "/src/styles/mixins";

.outerWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper {
  max-width: $maxContentWidth;
  width: 100%;
}

.topOuterBg {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5fbff;
}

.footerWrapper {
  padding: 0px 20px;
}

.paymentsWrap {
  margin-bottom: 120px;
}
