@import "/src/styles/variables";
@import "/src/styles/mixins";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;
  box-sizing: border-box;

  text-align: center;
  h3 {
    font-size: 48px;
    margin: 50px;

    span {
      color: $blue;
    }
  }
}

.benefitsWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 70px;
  row-gap: 20px;
  max-width: 950px;
}

.benefitsWrapperSlider {
  display: none;
}

.benefitItem {
  img {
    width: 120px;
    height: 120px;
  }

  p {
    font-size: 20px;
    font-weight: 700;
    max-width: 185px;
  }
}

@media (max-width: $mobileScreen) {
  .wrapper {
    display: block;
  }
  .benefitsWrapper {
    display: none;
  }
  .benefitsWrapperSlider {
    display: initial;
  }
  .benefitItem {
    display: flex !important;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
}
