@import "/src/styles/variables";
@import "/src/styles/mixins";

.wrapper {
  background-color: $pageGreyBaclground;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    text-align: center;
  }
}

.companyLogo {
  margin-top: 20px;
  margin-bottom: 50px;
  height: 60px;
}
