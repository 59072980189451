@import "/src/styles/variables";

.period {
  margin-top: 10px;

  span {
    text-decoration: underline;
  }
}

@media (max-width: $mobileScreen) {
  .arrow {
    transform: rotate(90deg);
    margin-top: 30px;
  }
}
