@import "/src/styles/variables";
@import "/src/styles/mixins";

.pricingTopOuterWrapper {
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.pricingTopWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 540px;
  padding: 0px 20px;

  h1 {
    font-size: 60px;
    font-weight: 700;
    text-align: center;
    line-height: 70px;
    margin-bottom: 20px;
  }

  p {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 20px;
  }
}

.getStartedButton {
  button {
    border: none;
    background-color: $blue;
    color: white;
    width: 300px;
    height: 70px;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 700;
    transition: $defaultTransition;

    &:hover {
      @include mainButtonHover;
    }
  }
}

.demoButton {
  width: 300px;
  height: 70px;
  background-color: white;
  border: 2px solid black;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 700;
  margin-top: 20px;
  transition: $defaultTransition;
  margin-bottom: 150px;

  &:hover {
    @include mainButtonHover;
    color: white;
  }
}

@media (max-width: $mobileScreen) {
  .getStartedButton {
    width: 100%;
    button {
      width: 100%;
    }
  }
  .pricingTopWrapper {
    h1 {
      font-size: 50px;
      line-height: 55px;
    }
  }
  .demoButton {
    width: 100%;
  }
}
