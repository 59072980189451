@import "/src/styles/variables";
@import "/src/styles/mixins";

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.paymentTypeWrapper {
  border: 1px solid #8d9cc7;
  border-radius: 5px;
  max-width: 300px;
  width: 100%;
  display: flex;
}

.switchButton {
  border-radius: 5px;
  border: none;
  background-color: white;
  color: rgb(50, 50, 50);
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  height: 42px;
}

.selectedSwitchButton {
  background-color: $blue;
  color: white;
  border-radius: 5px 0px 0px 5px;

  &:nth-child(2) {
    border-radius: 0px 5px 5px 0px;
  }
}

.footer {
  margin-top: 5px;
  margin-left: 280px;
  color: grey;
  font-size: 12px;
  font-weight: 500;
}

@media (max-width: 460px) {
  .footer {
    margin-left: 160px;
  }
}
