@import "./../../../styles/mixins";
@import "./../../../styles/variables";

.wrapper {
  display: flex;
  margin-top: 100px;
  margin-bottom: 100px;
  padding: 0px 20px;
  h1 {
    font-size: 60px;
    line-height: 65px;
    margin: 40px 0px;
  }
  p {
    font-size: 20px;
    margin-bottom: 30px;
  }
  button {
    border: none;
    background-color: $blue;
    color: white;
    width: 300px;
    height: 70px;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 700;
    transition: all 0.3s ease;
    &:hover {
      @include mainButtonHover;
    }
  }
}

.imgWrap {
  img {
    max-height: 445px;
    max-width: 623px;
  }
}

@media (max-width: 900px) {
  .wrapper {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: $mobileScreen) {
  .wrapper {
    button {
      width: 100%;
    }
  }
  .imgWrap {
    img {
      width: 100%;
      height: 100%;
    }
  }
}
