@import "./../../../../styles/variables";
@import "./../../../../styles/mixins";

.outerWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 370px;
  width: 100%;
  text-align: center;
  h1 {
    margin-bottom: 0px;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  max-height: 350px;
  overflow-y: auto;

  @include customScrollBar;
}

.questionTitle {
  font-weight: 700;
  margin: 20px 0px;

  span {
    font-weight: normal;
    word-wrap: break-word;
    white-space: normal;
  }
}

.questionWrapper {
  padding: 10px 20px 10px 0px;

  input {
    border: 1px dashed grey;
    cursor: pointer;
  }
}

.answersPreview {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  gap: 20px;
}

@media (max-height: 670px) {
  .content {
    margin-bottom: 80px;
  }
}

@media (max-width: $mobileScreen) {
  .answersPreview {
    display: flex;
    flex-direction: column;
  }
}
