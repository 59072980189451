@import "/src/styles/variables";
@import "/src/styles/mixins";

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
  margin-bottom: 50px;
}

.title {
  font-size: 52px;
  font-weight: 900;
}

.subtitle {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.paymentsWrap {
  margin-bottom: 120px;
}

@media (max-width: $mobileScreen) {
  .header {
    margin-top: 0px;
  }
}
