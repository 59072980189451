@import "./../../../../styles/variables";
@import "./../../../../styles/mixins";

.wrapper {
  border: 1px solid #cad7ff;
  background-color: #e9edff;
  padding: 20px 25px;
  font-size: 20px;
  font-weight: 700;
  border-radius: 12px;
  max-width: 360px;
  width: 100%;
  height: 500px;
  box-sizing: border-box;
  position: relative;

  ul {
    margin-top: 30px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  li {
    list-style: none;
    display: flex;
    width: 100%;

    p {
      font-weight: normal;
      font-size: 18px;
    }

    img {
      margin-right: 10px;
    }
  }
}

.trialLabel {
  font-size: 16px;
  font-weight: 900;
  color: $blue;
  position: absolute;
  bottom: 0;
  margin-bottom: 100px;
  align-self: flex-start;
}

.personalPlanLabel {
  color: gold;
  font-size: 14px;
}

.alert {
  margin-top: 20px;
  font-size: 14px;
  font-weight: normal;
  max-width: 150px;
  text-align: center;
  color: $red;
  min-width: 100%;
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column-reverse;
}

.buttonWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    position: absolute;
    bottom: 0;
    margin-bottom: 20px;
    border: none;
    background-color: $blue;
    color: white;
    max-width: 320px;
    height: 70px;
    width: 85%;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 700;
    transition: all 0.3s ease;
    &:hover {
      @include mainButtonHover;
    }
  }
}

.price {
  font-size: 40px;
}

.pricePerForm {
  font-size: 25px;
}

.lightPlan {
  background-color: #f7f7f7;
}

.darkPlan {
  background-color: #001f7a;

  p {
    color: white;
  }
}

.additionalPurchasesTitle {
  margin-top: 20px;
}

.additionalPurchasesWrapper {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  flex-wrap: wrap;

  button {
    cursor: pointer;
    background-color: white;
    border: 1px solid grey;
  }
}

.title {
  font-size: 24px;
  font-weight: 900;
  line-height: 24px;
  margin-bottom: 10px;
}

.counterOuterWrap {
  display: flex;
  justify-content: center;
}

.counterWrap {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  max-width: 210px;
}

.counterInputWrap {
  display: flex;
  align-items: center;

  input {
    border: 1px solid $grey;
    height: 38px;
    padding: 0px 10px;
    max-width: 50px;
    border-radius: 0px 10px 10px 0px;
    font-weight: 900;
    font-size: 16px;
  }
}

.counterImgWrap {
  background-color: rgb(231, 231, 231);
  border-radius: 10px 0px 0px 10px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formsCounterBtnsWrap {
  display: flex;
  column-gap: 5px;
}

.decrementForms {
  height: 38px;
  width: 38px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    border-radius: 100px;
    height: 38px;
    width: 38px;
    border: 1px solid $grey;
    background-color: white;
    cursor: pointer;
    transition: $defaultTransition;

    &:hover {
      transition: $defaultTransition;
      background-color: $grey;
    }
  }
}

.incrementForms {
  height: 38px;
  width: 38px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    border-radius: 100px;
    height: 38px;
    width: 38px;
    border: none;
    background-color: $blue;
    cursor: pointer;
    transition: $defaultTransition;

    &:hover {
      transition: $defaultTransition;
      background-color: $buttonHoverColor;
    }
  }
}
