@import "./../../styles/variables";
@import "./../../styles/mixins";

.outerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;
  background-color: #f4f4f5;
}

.alertWrapper {
  margin-bottom: 30px;
}

.wrapper {
  max-width: $maxContentWidth;
  width: 100%;
  margin-top: 100px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  h2 {
    margin-bottom: 10px;
  }
}

.elementWrapper {
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 0px 10px 0px $shadowColor;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;

  h3 {
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.trackingElement {
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 0px 10px 0px $shadowColor;
  border-radius: 5px;
  width: 100%;
  padding: 30px;

  h3 {
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.changeUserDataWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.horizontalElements {
  display: flex;
  column-gap: 20px;
}

.btn {
  border: none;
  background-color: $blue;
  color: white;
  width: 200px;
  height: 50px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 700;
  transition: all 0.3s ease;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    @include mainButtonHover;
  }
}

.title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 20px;
}

.currentEmailLabel {
  margin-bottom: 10px;
  color: grey;
}

@media (max-width: $mobileScreen) {
  .content {
    flex-direction: column;
  }
  .elementWrapper {
    flex-direction: column;
  }
  .arrow {
    transform: rotate(90deg);
    margin-top: 30px;
  }
  .horizontalElements {
    flex-direction: column;
    row-gap: 20px;
  }
}
