@import "/src/styles/variables";
@import "/src/styles/mixins";

.paymentsWrapperSlider {
  display: none;
}

@media (max-width: $mobileScreen) {
  .paymentsWrapperSlider {
    display: initial;
  }
  .sliderItem {
    box-sizing: border-box;
    padding: 0 10px;
    display: flex !important;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
}
