@import "./../../styles/variables";
@import "./../../styles/mixins";

.outerWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper {
  max-width: $maxContentWidth;
  width: 100%;
}

.footerWrapper {
  padding: 0px 20px;
}

.paymentsWrap {
  margin-bottom: 120px;
}
