@import "/src/styles/variables";

.wrapper {
  margin-top: -250px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 52px;
  }
}

.divider {
  border-bottom: 4px solid $blue;
  width: 100%;
  max-width: 56px;
  border-radius: 100px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 24px;
}

.itemsWrap {
  width: 100%;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  gap: 50px;
  flex-wrap: wrap;
}

.item {
  p {
    font-size: 24px;
    font-weight: 600;
    max-width: 265px;
  }

  img {
    height: 128px;
    width: 128px;
  }
}

@media (max-width: 1000px) {
  .wrapper {
    margin-top: -200px;
  }
  .itemsWrap {
    justify-content: center;
  }
}

@media (max-width: $mobileScreen) {
  .wrapper {
    h2 {
      font-size: 34px;
    }
  }
}
