@import "./../../styles/variables";
@import "./../../styles/mixins";
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

.outerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
  box-sizing: border-box;
  background-color: $pageGreyBaclground;
}

.wrapper {
  max-width: 768px;
  width: 100%;
  margin-top: 100px;

  h1 {
    text-align: center;
    margin-bottom: 20px;
  }

  a {
    color: black;
    font-size: 20px;
    text-decoration: none;
    transition: $defaultTransition;

    &:hover {
      color: $blue;
      transition: $defaultTransition;
    }
  }

  ul {
    li:not(:first-child) {
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px solid $grey;
    }
  }
}

.faqWrap {
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 0px 10px 0px $shadowColor;
  border-radius: 5px;
  width: 100%;
  padding: 30px;
}

.footerWrap {
  max-width: $maxContentWidth;
  width: 100%;
}

@media (max-width: $mobileScreen) {
  .wrapper {
    a {
      font-size: 15px;
    }
  }
}
