@import "./../../../styles/variables";
@import "./../../../styles/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  box-sizing: border-box;
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 24px;
  font-weight: 600;
}

.btn {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 30px 0px;
  border: none;
  background-color: $blue;
  color: white;
  width: 200px;
  height: 50px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 700;
  transition: all 0.3s ease;
  &:hover {
    @include mainButtonHover;
  }
}

.formTemplatesListWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;

  a {
    text-decoration: none;
    color: black;
  }
}

.paginationWrapper {
  margin-top: 20px;
}

@media (max-width: $mobileScreen) {
  .wrapper {
    min-width: 100%;
    box-sizing: border-box;
  }
  .content {
    width: 100%;
  }
}
