// colors
$blue: #3668ff;
$pageGreyBaclground: #f9f9f9;
$red: #df1b41;
$grey: rgb(179, 179, 179);
$navHover: #6ed3d5;
$shadowColor: rgb(198, 198, 198);
$buttonHoverColor: #1b253e;
$greenText: rgb(0, 167, 0);
$salesFunnelBgColor: #eff3ff;

// borders
$defaultBorderRadius: 10px;

// screens
$maxContentWidth: 1200px;

//media screens
$mobileScreen: 768px;

//other...
$defaultTransition: all 0.3s ease;
