@import "./variables";

// buttons
@mixin mainButtonHover {
  cursor: pointer;
  background-color: $buttonHoverColor;
  transition: $defaultTransition;
}

@mixin secondaryButtonHover {
  cursor: pointer;
  background-color: $blue;
  color: white;
  transition: $defaultTransition;
}

@mixin formActionButton {
  position: absolute;
  background-color: white;
  border: 1px solid rgb(205, 205, 205);
  border-radius: 100px;
  cursor: pointer;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px 0px $shadowColor;
  margin-right: -150px;
  transition: $defaultTransition;

  img {
    border: none;
    margin: 0px;
  }

  &:hover {
    background-color: rgb(231, 231, 231);
    transition: $defaultTransition;
  }
}

@mixin authModalWrapper {
  min-width: 400px;
  max-height: 700px;
  padding: 30px;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
}

@mixin authModalWrapperMobile {
  min-width: 100%;
  margin: 0px 20px;
  box-sizing: border-box;
}

@mixin calendlyModalWrapper {
  min-width: 400px;
  padding: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
}

@mixin calendlyModalWrapperMobile {
  min-width: 100%;
  box-sizing: border-box;
}

@mixin elementActionButton {
  background-color: white;
  border-radius: 100px;
  border: 1px solid rgb(205, 205, 205);
  box-shadow: 0px 0px 10px 0px $shadowColor;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@mixin actionButton {
  @include elementActionButton;
  box-shadow: none;
}

@mixin inputLabel {
  color: grey;
  font-size: 12px;
}

@mixin inputAuth {
  width: 100%;
  height: 62px;
  border: 1px solid rgb(220, 220, 220);
  border-radius: 5px;
  padding: 0px 15px;
  box-sizing: border-box;
  &:focus {
    outline: none;
  }
}

@mixin customScrollBar {
  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $blue;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 5px;
  }
}
