@import "./../../../../styles/variables";
@import "./../../../../styles/mixins";

.outerWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  width: 100%;
  text-align: center;
  padding: 0px 20px;
  box-sizing: border-box;
  margin-bottom: 100px;

  h2 {
    margin: 20px 0px;
    font-size: 32px;
  }
}

.editWrapper {
  text-align: start;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  p {
    color: grey;
    text-align: start;
    font-size: 12px;
  }
}

.notEditable {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  input {
    border-color: $grey;
    color: grey;
  }
}

.nameSection {
  display: flex;
  column-gap: 10px;
}

.otherInfo {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.deleteButton {
  @include actionButton;
  box-shadow: 0px 0px 10px 0px $shadowColor;
}

.elementAndDeleteBtn {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.labelInput {
  border: none;
  border-bottom: 1px solid $blue;
  margin-bottom: 5px;
}
