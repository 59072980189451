@import "/src/styles/variables";
@import "/src/styles/mixins";

@mixin navigationBtn {
  background-color: #ffffff;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 6px 0px rgb(218, 218, 218);

  svg {
    fill: #818181;
    transition: fill 0.3s ease;
  }

  &:hover {
    svg {
      fill: $blue;
    }
  }
}

.wrapper {
  display: flex;
  column-gap: 15px;
  align-items: center;
  color: #818181;
}

.previous {
  @include navigationBtn;
  svg {
    transform: rotate(270deg);
  }
}

.next {
  @include navigationBtn;
  svg {
    transform: rotate(90deg);
  }
}
