@import "/src/styles/variables";
@import "/src/styles/mixins";

.title {
  font-size: 28px;
  font-weight: 700;
  line-height: 28px;
  max-width: 300px;
}

.subtitle {
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.labelImg {
  margin-top: -10px;
  margin-right: -10px;
}

.labelImg {
  position: absolute;
  right: 0;
  margin-right: 10px;
}

.contentImg {
  max-width: 300px;
  margin-top: 20px;
}

@media (max-width: $mobileScreen) {
  .title {
    max-width: 180px;
  }
}
