@import "./../../styles/variables";
@import "./../../styles/mixins";

.inputWrapper {
  width: 100%;
}

.wrapper {
  width: 100%;
  input {
    font-family: "DM Sans", sans-serif;
    height: 50px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 0px 15px;
    width: 100%;
    border: 1px solid $grey;
    font-size: 14px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: rgb(171, 171, 171);
    }
  }
}

.inputLabel {
  position: relative;
  @include inputLabel;
  color: $grey;
  margin-bottom: -9px;
  margin-left: 15px;
  z-index: 9;
  background-color: white;
  max-width: fit-content;
  padding: 0px 5px;
}
