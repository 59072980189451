@import "./../../../../styles/variables";
@import "./../../../../styles/mixins";

.wrapper {
  display: flex;
  column-gap: 20px;
}

.calendarItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 10px;

  input {
    max-width: 130px;
    height: 35px;
    box-sizing: border-box;
    border: 1px solid $grey;
    padding: 0px 10px;
    cursor: pointer;
    border: 1px solid $grey;
    outline: none;
    &:focus-visible {
      outline: none;
    }
  }
}

.clearButton {
  border: none;
  background-color: $blue;
  cursor: pointer;
  height: 35px;
  color: white;
  border-radius: 6px;
  padding: 0 15px;
}

@media (max-width: $mobileScreen) {
  .wrapper {
    flex-direction: column;
    row-gap: 10px;
    width: 100%;
    margin-bottom: 10px;
  }
  .calendarItem {
    display: grid;
    grid-template-columns: 1fr 6fr;
    column-gap: 10px;

    input {
      width: 100%;
    }
  }
}
