@import "/src/styles/variables";
@import "/src/styles/mixins";

.wrapper {
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: $maxContentWidth;
  width: 100%;
  padding: 0px 20px;
  box-sizing: border-box;

  h1 {
    text-align: center;
    font-size: 44px;
    font-weight: 800;
    line-height: 50px;
    margin-top: 30px;
    margin-bottom: 20px;

    span {
      color: $blue;
    }
  }

  .subtitle {
    text-align: center;
    font-size: 44px;
    font-weight: 800;
    line-height: 50px;
    margin-bottom: 40px;

    span {
      color: $blue;
    }
  }
}

.buttonsWrap {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}

.videoSection {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .video {
    max-width: 100%;
  }
}

.getStartedButton {
  button {
    border: none;
    background-color: $blue;
    color: white;
    width: 270px;
    height: 70px;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 700;
    transition: $defaultTransition;
    &:hover {
      @include mainButtonHover;
    }
  }
}

.logoutBtnWrap {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.logoutBtn {
  background-color: white;
  border: 2px solid black;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 700;
  margin-top: 10px;
  cursor: pointer;
  color: black;
}

.formPreview {
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 44px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 20px;
    color: $blue;
  }
  iframe {
    border: none;
    max-width: 600px;
    max-height: 600px;
    margin-bottom: 10px;
  }
}

@media (max-width: 1000px) {
  .wrapper {
    flex-direction: column;
  }
  .buttonsWrap {
    justify-content: center;
    margin-bottom: 50px;
  }
}

@media (max-width: $mobileScreen) {
  .videoSection {
    margin-top: 0px;
  }
  .wrapper {
    margin-bottom: 40px;
    h1 {
      font-size: 28px;
      line-height: 33px;
    }
    .subtitle {
      font-size: 18px;
      line-height: 20px;
    }
  }
  .getStartedButton {
    width: 100%;
    button {
      width: 100%;
    }
  }
  .buttonsWrap {
    width: 100%;
  }
}
