.wrapper {
  width: 100%;
  text-align: center;

  h2 {
    font-size: 36px;
    margin-top: 30px;
    margin-bottom: 80px;
  }

  img {
    width: 150px;
    height: 50px;
  }

  p {
    margin-top: 30px;
    font-weight: 700;
    font-size: 14px;
  }
}

.iconsWrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 20px;
  padding: 0px 20px;

  img {
    width: 140px;

    &:nth-last-child(1) {
      width: 200px;
      height: 45px;
    }
  }
}
