@import "./../../../styles/variables";
@import "./../../../styles/mixins";

.wrapper {
  display: flex;
  align-items: center;

  .searchIcon {
    position: absolute;
    margin-left: 5px;
  }

  input {
    padding-left: 30px;
    box-sizing: border-box;
    border: 1px solid $grey;
    height: 35px;
    max-width: 250px;
    width: 100%;
    &:focus-visible {
      outline-color: $blue;
    }
  }

  .clearInputBtn {
    background-color: white;
    border: none;
    margin-left: -22px;
    margin-top: 2px;
    padding: 0px;
    cursor: pointer;
  }
}

@media (max-width: $mobileScreen) {
  .wrapper {
    width: 100%;
    input {
      max-width: 100%;
    }
  }
}
