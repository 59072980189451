@import "./../../../styles/variables";
@import "./../../../styles/mixins";

.outerWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 40px;
  box-sizing: border-box;
  position: relative;
}

.wrapper {
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 0px 10px 0px $shadowColor;
  border-radius: 5px;
  width: 100%;
  max-width: $maxContentWidth;
  display: flex;
  flex-direction: column;
}

.devWrapper {
  position: absolute;
  max-width: $maxContentWidth;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 40px);
  width: calc(100% - 40px);
}

.devZapText {
  background-color: rgba(255, 255, 255, 0.7);
  height: 100%;
  z-index: 998;
  justify-content: center;
  align-items: center;
  display: flex;
}

.zapContainer {
  padding: 30px;
  &.blurred {
    filter: blur(10px);
  }
}

.devZapTextWrap {
  padding: 0 40px;
  text-align: center;
}
