@import "/src/styles/variables";
@import "/src/styles/mixins";

.wrapper {
  max-width: 300px;
  width: 100%;
}

.content {
  padding-top: 20px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
}

.inputs {
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  textarea {
    border: 1px solid $grey;
    box-shadow: none;
    border-radius: 5px;
    font-size: 14px;
  }
}

.btnWrap {
  display: flex;
  justify-content: center;
  margin-top: 20px;

  button {
    border: none;
    background-color: $blue;
    color: white;
    width: 100px;
    height: 40px;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 700;
    transition: all 0.3s ease;
    &:hover {
      @include mainButtonHover;
    }
  }
}

.errorMessage {
  font-size: 12px;
  color: $red;
  max-width: 100%;
  text-align: center;
  margin-top: 10px;
}

@media (max-width: $mobileScreen) {
  .btnWrap {
    button {
      width: 100%;
    }
  }
}
