@import "./../../../styles/variables";

.wrapper {
  max-width: 150px;
  width: 100%;
  height: 35px;
  border: 1px solid $grey;
  background-color: white;
  border-radius: 0px;
  color: black;
  padding: 0 5px;
  font-size: 14px;
  &:focus-visible {
    outline-color: $blue;
  }
}

@media (max-width: $mobileScreen) {
  .wrapper {
    max-width: 100%;
    margin-bottom: 10px;
  }
}
