@import "./../../../styles/variables";
@import "./../../../styles/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px;
}

.paymentTypeWrapper {
  border: 1px solid grey;
  border-radius: 5px;
  max-width: 400px;
  width: 100%;
  padding: 10px;
  display: flex;
}

.switchButton {
  border: none;
  background-color: white;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  padding: 10px;
  cursor: pointer;
}

.selectedSwitchButton {
  background-color: #001f7a;
  color: white;
  border-radius: 5px;
}

.paymentPlansWrapper {
  display: flex;
  width: 100%;
  column-gap: 20px;
  row-gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.seeMorePlansBtn {
  margin-top: 50px;
  cursor: pointer;
  width: 100%;
  max-width: 300px;
  height: 50px;
  font-size: 16px;
  font-weight: 700;
}

.showMoreBtn {
  display: initial;
  border: none;
  background-color: $buttonHoverColor;
  color: white;
  width: 200px;
  height: 50px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 700;
  transition: all 0.3s ease;
  margin-top: 50px;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .paymentPlansWrapper {
    flex-direction: column;
    align-items: center;
    row-gap: 50px;
  }
}

@media (max-width: $mobileScreen) {
  .paymentPlansWrapper {
    display: none;
  }
  .showMoreBtn {
    display: none;
  }
}
