@import "/src/styles/variables";

.outerWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper {
  max-width: 420px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
}

.inputsWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  input {
    width: 100%;
    height: 62px;
    border: 1px solid rgb(220, 220, 220);
    border-radius: 5px;
    padding: 0px 15px;
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }
  .errorInput {
    border: 1px solid rgb(234, 0, 0);
  }
  button {
    width: 100%;
    height: 50px;
    border: none;
    background-color: $blue;
    color: white;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 700;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
      background-color: $buttonHoverColor;
      transition: all 0.3s ease;
    }
  }
}

.errorMessage {
  color: rgb(234, 0, 0);
}

.successMessage {
  color: green;
}
