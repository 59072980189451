@import "./../../../styles/variables";
@import "./../../../styles/mixins";

.planWrapper {
  padding: 40px 20px 20px 20px;
}

.inputsWrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: auto;
  margin-top: 20px;
}

.checkboxWrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.saveBtn {
  width: 100%;
  margin-top: 20px;
  border: none;
  background-color: $blue;
  color: white;
  width: 100%;
  height: 70px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 700;
  transition: all 0.3s ease;
  &:hover {
    @include mainButtonHover;
  }
}

.userEmail {
  margin-bottom: 20px;
}

.errorMessage {
  text-align: center;
  color: $red;
}

.disabledInput {
  input {
    border-color: $grey !important;
    color: $grey;
  }
}
