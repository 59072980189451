@import "./../../../styles/variables";
@import "./../../../styles/mixins";

.wrapper {
  max-width: $maxContentWidth;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.button {
  box-sizing: border-box;
  border: 1px dashed grey;
  color: grey;
  background-color: rgb(245, 245, 245);
  width: 190px;
  height: 190px;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  p {
    transition: $defaultTransition;
  }

  &:hover {
    p {
      transition: $defaultTransition;
      transform: scale(1.1);
    }
  }
}

@media (max-width: $mobileScreen) {
  .wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }
  .button {
    min-height: 200px;
  }
}
