@import "./../../../../styles/variables";
@import "./../../../../styles/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid grey;
  width: 190px;
  height: 190px;
  text-align: center
}

.editBtn {
  @include elementActionButton;
  margin-right: -185px;
  margin-top: -65px;
  z-index: 9;
}

.deleteBtn {
  @include elementActionButton;
  margin-right: -185px;
  margin-bottom: -35px;
  margin-top: 5px;
  z-index: 9;
}
