@import "/src/styles/variables";
@import "/src/styles/mixins";

.wrapper {
  margin-top: 150px;
  margin-bottom: 100px;
  display: flex;
  max-width: $maxContentWidth;
  justify-content: space-between;
  width: 100%;
  padding: 0px 20px;
  box-sizing: border-box;

  h1 {
    font-size: 52px;
    font-weight: 900;
    line-height: 55px;
    margin-bottom: 20px;
  }
}

.subtitle {
  font-size: 20px;
  font-weight: 400;
}

.textWrap {
  max-width: 490px;
}

.buttonsWrap {
  display: flex;
  gap: 20px;
  margin-top: 30px;
}

.videoSection {
  display: flex;
  flex-direction: column;
  align-items: center;

  .video {
    max-width: 550px;
    max-height: 521px;
  }
}

.getStartedButton {
  button {
    border: none;
    background-color: $blue;
    color: white;
    width: 270px;
    height: 70px;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 700;
    transition: $defaultTransition;
    &:hover {
      @include mainButtonHover;
    }
  }
}

.demoButton {
  min-width: 270px;
  height: 70px;
  background-color: white;
  border: 2px solid black;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 700;
  transition: $defaultTransition;

  &:hover {
    @include mainButtonHover;
    color: white;
  }
}

@media (max-width: 1000px) {
  .wrapper {
    flex-direction: column;
  }
  .textWrap {
    max-width: 100%;
  }
  .buttonsWrap {
    justify-content: center;
    margin-bottom: 50px;
  }
}

@media (max-width: $mobileScreen) {
  .wrapper {
    h1 {
      font-size: 34px;
      line-height: 40px;
    }
  }
  .getStartedButton {
    width: 100%;
    button {
      width: 100%;
    }
  }
  .demoButton {
    width: 100%;
  }
  .buttonsWrap {
    flex-direction: column;
  }
}
