@import "./../../styles/variables";
@import "./../../styles/mixins";

.outerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px 20px;
  box-sizing: border-box;
}

.wrapper {
  max-width: 768px;
  width: 100%;
  margin-top: 100px;

  a {
    text-decoration: none;
    color: $blue;
  }

  p {
    margin-top: 20px;
  }

  ul {
    margin-top: 20px;
  }

  span {
    color: $greenText;
  }

  img {
    max-width: 100%;
  }
}

.step {
  margin-top: 20px;
  display: block;
  font-size: 20px;
}

.numericUl {
  list-style: decimal;
}

.subUl {
  margin-top: 0px !important;
}

.footerWrap {
  max-width: $maxContentWidth;
  width: 100%;
}
