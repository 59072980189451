@import '/src/styles/variables';
@import "/src/styles/mixins";

.outerWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: #f4f4f5;
  height: 100%;
}

.wrapper {
  max-width: $maxContentWidth;
  margin-top: 150px;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  padding: 0px 20px;

  a {
    text-decoration: none;
    color: black;
  }
}

.footerOuterWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footerWrapper {
  width: 100%;
  max-width: $maxContentWidth;
  padding: 0px 20px;
}

@media (max-width: $mobileScreen) {
  .wrapper {
    display: flex;
    flex-direction: column;
  }
}
