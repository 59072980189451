@import "./../../../../../../styles/variables";
@import "./../../../../../../styles/mixins";

.wrapper {
  background-color: white;
  width: 150px;
  min-height: 150px;
  box-sizing: border-box;
  border-radius: 17px;
  border: 1px solid $blue;
  box-shadow: 0px 0px 10px 0px rgb(222, 222, 222);
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: $blue;
  font-weight: 700;
  transition: $defaultTransition;
  cursor: pointer;

  .questionText {
    box-sizing: border-box;
    border: none;
    text-align: center;
    width: 100%;
    border-bottom: none;
    &:focus {
      outline: none;
    }
  }

  .inputEmpty {
    border-bottom: 1px solid grey;
  }
}

.removeButton {
  @include actionButton;
  margin-left: 100px;
  margin-top: -20px;
}
