@import "./../../../styles/variables";
@import "./../../../styles/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.searchWrapper {
  display: flex;
  width: 100%;
  margin-top: 20px;

  button {
    height: 30px;
    background-color: $blue;
    color: white;
    border: none;
    border-radius: 0px 3px 3px 0px;
    cursor: pointer;
  }

  input {
    box-sizing: border-box;
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
    border: 1px solid black;
    border-radius: 3px 0px 0px 3px;
    padding: 0px 8px;

    &:focus {
      outline: none;
    }
  }
}

.iconsWrapper {
  border-radius: 5px;
  background-color: white;
  height: auto;
  height: 250px;
  width: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1000;
  margin-top: 20px;
  padding: 5px;

  @include customScrollBar;
}

.iconsLoadingWrapper {
  grid-column: 2;
  display: flex;
  justify-content: center;
}

.iconsSelectWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}

.iconsSelect {
  padding: 15px 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: rgb(241, 241, 241);
  }
}

.iconsStyleSelect {
  height: 30px;
  margin-left: 10px;
}

.customLogoUploaderWrapper {
  max-width: 210px;
  max-height: 50px;

  p {
    font-size: 12px;
  }

  button {
    padding: 0px 10px;
    border-radius: 3px;
  }
}

.errorMessage {
  color: $red;
  margin-bottom: 5px;
}

@media (max-height: 670px) {
  .iconsWrapper {
    max-height: 20vh;
  }
}

@media (max-width: $mobileScreen) {
  .iconsWrapper {
    max-width: 80vw;
  }
}
