@import "./../../../../styles/variables";
@import "./../../../../styles/mixins";

.outerWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  width: 100%;
  text-align: center;
  padding: 0px 20px;

  h2 {
    margin: 20px 0px;
    font-size: 32px;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 20px;
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;

  @include customScrollBar;
}

.questionInnerWrap {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.removeQuestionBtn {
  @include actionButton;
  box-shadow: 0px 0px 10px 0px $shadowColor;
}

.duplicateQuestionBtn {
  @include actionButton;
  box-shadow: 0px 0px 10px 0px $shadowColor;
}

@media (max-height: 670px) {
  .addQuestionBtn {
    margin-bottom: 80px;
  }
}
