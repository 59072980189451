@import "./../../../../styles/variables";

.wrapper {
  max-width: 1100px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  font-size: 14px;
}

.title {
  font-weight: 600;
}

.tradesList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
  row-gap: 10px;
  column-gap: 20px;
  color: $blue;

  a {
    display: flex;
    align-items: center;

    img {
      height: 30px;
      width: 30px;
    }
  }

  p:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: $blue;
    display: flex;
    align-items: center;
  }
}

.exploreMore {
  display: flex;
  text-decoration: none;
  color: $blue;
  border-bottom: 2px solid $blue;
  max-width: 140px;
  padding-bottom: 5px;
  margin-top: 20px;
  align-items: center;

  img {
    margin-top: 2px;
    margin-left: 3px;
  }
}
