@import "./../../../styles/variables";
@import "./../../../styles//mixins";

.outerWrapper {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  bottom: 0;
  position: fixed;
}

.wrapper {
  max-width: $maxContentWidth;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  column-gap: 10px;
  padding: 20px 0px;
}

.backButton {
  background-color: white;
  border: 2px solid $blue;
  border-radius: $defaultBorderRadius;
  color: $blue;
  font-size: 16px;
  height: 40px;
  width: 100px;

  &:hover {
    cursor: pointer;
    background-color: $blue;
    color: white;
  }
}

.continueButtonMargin {
  margin-left: 110px;
}

.continueButton {
  background-color: $blue;
  border: none;
  border-radius: $defaultBorderRadius;
  color: white;
  font-size: 16px;
  height: 40px;
  width: 100px;
  cursor: pointer;

  &:hover {
    cursor: pointer;
    background-color: $buttonHoverColor;
  }
}

.continueButtonDisabled {
  background-color: grey;
  &:hover {
    cursor: auto;
    background-color: grey;
  }
}
