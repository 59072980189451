@import "./../../../styles/variables";
@import "./../../../styles/mixins";

.wrapper {
  padding: 20px;
}

.title {
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 20px;
}

.inputsWrap {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.submitBtnWrap {
  width: 100%;
  display: flex;
  justify-content: center;
}

.submitBtn {
  margin-top: 20px;
  border: none;
  background-color: $blue;
  color: white;
  width: 200px;
  height: 50px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 700;
  transition: all 0.3s ease;
  &:hover {
    @include mainButtonHover;
  }
}
