.contentFooterWrap {
  display: flex;
  margin-top: 20px;
  max-width: 500px;
  justify-content: space-between;

  .title {
    font-weight: 900;
  }

  .contentFooterItem {
    max-width: 240px;
    width: 100%;
  }
}
