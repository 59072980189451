@import "./../../../../styles/mixins";
@import "./../../../../styles/variables";

.wrapper {
  @include authModalWrapper;
}

.content {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

@media (max-height: 670px) {
  .wrapper {
    max-height: 80vh;
  }
}

@media (max-width: $mobileScreen) {
  .wrapper {
    @include authModalWrapperMobile;
  }
}
