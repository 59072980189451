@import "./../../../styles/variables";
@import "./../../../styles/mixins";

.formButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: none;
  background-color: white;
  border: 3px solid rgb(181, 181, 181);
  width: 170px;
  height: 170px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 10px;
  box-sizing: border-box;

  .formPreviewContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: $defaultTransition;

    p {
      text-align: center;
      max-width: 130px;
      word-wrap: break-word;
      white-space: normal;
      font-size: 14px;
      color: black;
    }
  }

  img {
    border: 1px solid rgb(196, 196, 196);
    border-radius: 5px;
    margin-bottom: 10px;
    object-fit: contain;
  }
}

.formButtonPublushed {
  border: 3px solid $blue;
}

.publishActionBtn {
  max-width: 80px;
  border: 1px solid $grey;
  width: 100%;
  height: 25px;
  border-radius: 5px;
  transition: all 0.3s ease;
  &:hover {
    background-color: $shadowColor;
    cursor: pointer;
  }
}

.unpublishBtn {
  background-color: $red;
  color: white;
  &:hover {
    background-color: rgb(133, 0, 0);
    cursor: pointer;
  }
}

.deleteFormButton {
  @include formActionButton;
  margin-top: -13px;
  margin-left: 5px;
}

.editFormButton {
  @include formActionButton;
  margin-top: 22px;
  margin-left: 5px;
}

.duplicateFormButton {
  @include formActionButton;
  margin-top: 57px;
  margin-left: 5px;
}

.shareFormButton {
  @include formActionButton;
  margin-top: 92px;
  margin-left: 5px;
}

.qrCodeButton {
  @include formActionButton;
  margin-top: 127px;
  margin-left: 5px;
}
