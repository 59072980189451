.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.paymentTypeWrapper {
  border: 1px solid grey;
  border-radius: 5px;
  max-width: 400px;
  width: 100%;
  padding: 10px;
  display: flex;
}

.switchButton {
  border: none;
  background-color: white;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  padding: 10px;
  cursor: pointer;
}

.selectedSwitchButton {
  background-color: #001f7a;
  color: white;
  border-radius: 5px;
}

.footer {
  margin-left: 200px;
  font-style: italic;
  color: rgb(92, 92, 92);
  font-weight: 600;
}
