@import "/src/styles/variables";
@import "/src/styles/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 100px;

  .btn {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    border: none;
    background-color: $blue;
    color: white;
    width: 200px;
    height: 40px;
    border-radius: 5px;
    font-size: 17px;
    font-weight: 700;
    transition: all 0.3s ease;
    &:hover {
      @include mainButtonHover;
    }
  }
}
