@import "./../../styles//variables";

.wrapper {
  width: 100%;
  min-height: 100vh;
  background-color: $pageGreyBaclground;
}

.progressBarOuterWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.progressBarWrapper {
  max-width: 500px;
  width: 100%;
}
