@import "/src/styles/variables";
@import "/src/styles/mixins";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  row-gap: 20px;
  color: rgb(29, 29, 29);
  min-height: 479px;
}

.headerLabel {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.title {
  font-weight: 800;
  font-size: 28px;
  text-align: center;
}

.subtitle {
  text-align: center;
}

.list {
  list-style: none;
  padding-left: 0;

  li {
    position: relative;
    padding-left: 20px;
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
  }

  li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    background-color: $blue;
    border-radius: 50%;
  }
}

.buttonsWrap {
  display: flex;
  gap: 10px;

  button {
    &:nth-child(1) {
      border: none;
      background-color: $blue;
      color: white;
      width: 200px;
      height: 50px;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 700;
      transition: all 0.3s ease;
      &:hover {
        @include mainButtonHover;
      }
    }

    &:nth-last-child(1) {
      border: 2px solid $blue;
      background-color: white;
      color: $blue;
      width: 200px;
      height: 50px;
      border-radius: 5px;
      font-size: 14px;
      font-weight: 700;
      transition: all 0.3s ease;
      &:hover {
        @include mainButtonHover;
        color: white;
        border: none;
      }
    }
  }
}

.errorMessage {
  color: $red;
}

@media (max-width: 470px) {
  .buttonsWrap {
    flex-direction: column;
  }
}
