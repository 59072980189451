@import "./../../styles/variables";
@import "./../../styles/mixins";

.outerWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper {
  max-width: $maxContentWidth;
  width: 100%;
  padding: 0px 20px;
  box-sizing: border-box;
}

.getUpWrapper {
  margin-top: 200px;
}

@media (max-width: $mobileScreen) {
  .getUpWrapper {
    margin-top: 0px;
  }
}
