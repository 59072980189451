@import "./../../../../styles/variables";

.outerWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrapper {
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  width: 100%;
  text-align: center;

  h1 {
    margin: 20px 0px;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-top: 20px;
}

.errorMessage {
  color: $red;
}

.dontUseLogo {
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    height: 15px;
    width: 15px;
    cursor: pointer;
  }
}
