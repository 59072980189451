@import "./../../../styles/variables";

.questionTitle {
  font-size: 22px;
  font-weight: 700;
  margin: 20px 0px;
  overflow-y: auto;
  max-height: 300px;
  word-wrap: break-word;
  white-space: normal;
  max-width: 400px;
}

.answerOptions {
  display: flex;
  gap: 20px;
  overflow-y: hidden;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
  max-width: 600px;
}

.questionsWrapper {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentWithPaddingsWrap {
  padding: 0px;
  width: 100%;
  box-sizing: border-box;
}

.writeYourAnswerDivider {
  border-bottom: 1px solid $grey;
  margin-top: 20px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;

  p {
    background-color: $pageGreyBaclground;
    max-width: fit-content;
    margin-bottom: -9px;
    padding: 0px 10px;
  }
}

.dateAndTimePickerWrapper {
  width: 100%;
  display: flex;
  gap: 10px;
}

.datePickerWrap {
  input {
    max-width: 130px;
  }
}

.inputElement {
  width: 100%;
  max-width: 540px;
}

.fileUplaoderWrap {
  max-width: 300px;
  width: 100%;
}

.errorMessage {
  color: $red;
  margin-bottom: 10px;
}

@media (max-width: $mobileScreen) {
  .dateAndTimePickerWrapper {
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .contentWithPaddingsWrap {
    padding: 0px 20px;
  }
}
