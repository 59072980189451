@import "./../../../../../styles/variables";

.outerWrapper {
  display: flex;
  align-items: center;
  button {
    background-color: white;
    border: none;
    font-size: 30px;
    color: grey;
    padding: 0px;
    height: fit-content;
    cursor: pointer;
    margin-left: -30px;
  }
}

.wrapper {
  width: 100%;
  box-sizing: border-box;
  border: 1px dashed grey;
  color: grey;
  box-shadow: 3px 3px 10px 0px $shadowColor;
  height: 50px;
  border-radius: $defaultBorderRadius;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: white;
}

.content {
  display: flex;
  flex-direction: column;
}

.answerTypeLabel {
  color: $grey;
  font-size: 12px;
}
