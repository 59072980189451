@import '/src/styles/variables';
@import "/src/styles/mixins";

.outerWrapper {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
}

.wrapper {
  max-width: $maxContentWidth;
  width: 100%;
  height: 65px;
  z-index: 999;
  position: relative;
  padding: 0px 20px;
}
