@import "/src/styles/variables";
@import "/src/styles/mixins";
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

.outerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding: 0px 20px;
}

.wrapper {
  box-sizing: border-box;
  max-width: $maxContentWidth;
  width: 100%;
}

.footerWrapper {
  width: 100%;
  max-width: $maxContentWidth;
}

.border {
  margin-top: -175px;
  border: 1px solid transparent;
  border-radius: 36px;
  background-image: linear-gradient(white, white),
    linear-gradient(to top, white, #c0ccf5);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.contentSection {
  border-radius: 36px;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  padding: 136px 48px 0px 48px;
  display: flex;
  flex-direction: column;
  row-gap: 150px;
}

.gradient {
  background: linear-gradient(to top, white, transparent);
  min-width: 100vw;
  height: 200px;
  margin-top: -200px;
}

.formPreview {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-size: 44px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 20px;
    color: $blue;
  }
  iframe {
    border: none;
    max-width: 600px;
    max-height: 600px;
  }
}

@media (max-width: $mobileScreen) {
  .contentSection {
    padding: 40px 20px 0px 20px;
  }
}
