@import "./../../../../../styles/variables";
@import "./../../../../../styles/mixins";

.wrapper {
  width: 100%;
  max-width: 600px;
  text-align: center;
  padding-top: 30px;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 500px;
  overflow-y: auto;
  
  @include customScrollBar;
}

.title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
}

.selectWrap {
  display: flex;
  align-items: center;
  column-gap: 10px;
  border-bottom: 1px solid $blue;
  padding-bottom: 20px;
}

.select {
  max-width: 100px;
  width: 100%;
  height: 40px;
  border: 2px solid $blue;
  border-radius: $defaultBorderRadius;
  padding: 0px 10px;

  &:focus {
    outline: none;
  }
}

.conditions {
  margin-top: 20px;
  display: flex;
  align-items: center;
  column-gap: 10px;

  .select {
    max-width: 150px;
  }
}

.elementsWrap {
  display: flex;
  flex-direction: column;
}

.dividerWrap {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.orLabel {
  margin-bottom: -8px;
  background-color: white;
  width: 20px;
  z-index: 9;
  color: $grey;
}

.divider {
  width: 100%;
  max-width: 50px;
  height: 1px;
  background-color: $grey;
}

.addBtnWrap {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.addBtn {
  max-width: 40px;
  width: 100%;
  cursor: pointer;
  background-color: white;
  border: 1px solid $blue;
  color: $blue;
  border-radius: 10px;
}

.saveBtnWrap {
  margin-top: 50px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.saveBtn {
  border: none;
  background-color: $blue;
  color: white;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  font-size: 17px;
  font-weight: 700;
  transition: all 0.3s ease;

  &:hover {
    @include mainButtonHover;
  }
}

.removeBtn {
  background-color: white;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
}

@media (max-height: 670px) {
  .content {
    max-height: 60vh;
  }
}


@media (max-width: $mobileScreen) {
  .saveBtn {
    width: 100%;
  }
  .conditions {
    p {
      font-size: 14px;
    }
  }
}
