@import "./../../../../styles/variables";
@import "./../../../../styles/mixins";

.content {
  max-width: 1000px;
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 40px;
  margin-bottom: 20px;

  @include customScrollBar;
}

@media (max-height: 670px) {
  .content {
    max-height: 60vh;
  }
}
