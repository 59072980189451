@import "./../../../styles/variables";

.wrapper {
  background-color: white;
  width: 170px;
  height: 170px;
  border-radius: 17px;
  box-shadow: 3px 2px 10px 0px rgb(222, 222, 222);
  font-size: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: black;
  font-weight: 700;
  transition: $defaultTransition;
  box-sizing: border-box;

  input[type="radio"] {
    display: none;
  }

  .radioButton {
    width: 50px;
    height: 50px;
    position: relative;
  }

  &:hover {
    transform: scale(1.05);
    transition: $defaultTransition;
  }
}

.selected {
  border: 2px solid $blue;
}

.wrapper.checked {
  border: 1px solid #007bff;
}

.question {
  margin-top: 10px;
  max-width: 150px;
  word-wrap: break-word;
  white-space: normal;
}

@media (min-width: 360px) and (max-width: 470px) {
  .wrapper {
    width: 42vw !important;
    height: 42vw !important;
  }
}

@media (max-width: $mobileScreen) {
  .wrapper {
    width: 130px;
    height: 130px;
    border-radius: 10px;

    &:hover {
      transform: scale(1);
    }
  }
  .question {
    font-size: 14px;
  }
}
