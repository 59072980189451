@import "./../../../styles/variables";
@import "./../../../styles/mixins";

.apiKeyWrapper {
  display: flex;
  flex-direction: column;
}

.title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 20px;
}

.zapierApiKeyInputWrapper {
  display: flex;
  width: 100%;

  img {
    cursor: pointer;
  }
}

.copyStatus {
  position: absolute;
  margin-top: 30px;
  color: green;
  font-size: 12px;
}
