@import "/src/styles/variables";
@import 'react-datepicker/dist/react-datepicker';

.datePickerWrapper {
  background-color: white;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid $blue;
  box-shadow: 3px 3px 10px 0px $shadowColor;
  height: 45px;
  padding: 0px 10px;
  border-radius: $defaultBorderRadius;
  font-size: 16px;
  width: 100%;
}

.datePicker {
  border: none;
  height: 35px;
  font-size: 16px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: rgb(171, 171, 171);
  }
}

:global(.react-datepicker) {
  border-radius: 10px;
  border-color: #d8d8d8;
  box-shadow: 0px 0px 20px 0px #00000023;
}

:global(.react-datepicker__day) {
  padding: 5px;
  transition: 0.2s ease-in-out;
  border-radius: 50%;
}

:global(.react-datepicker__day--keyboard-selected) {
  background-color: rgb(229, 248, 255);
}

:global(.react-datepicker__day:hover, .react-datepicker__day:not([aria-disabled=true]):hover) {
  background-color: $blue;
  color: white;
  border-radius: 50%;
}

:global(.react-datepicker__day:not([aria-disabled=true]):hover) {
  background-color: $blue;
  color: white;
  border-radius: 50%;
}

:global(.react-datepicker__navigation) {
  height: 50px;
}

:global(.react-datepicker__navigation-icon::before) {
  border-color: #ffffff !IMPORTANT;
}
  
:global(.react-datepicker__header) {
  background-color: $blue;
  :global(.react-datepicker__current-month) {
    color: #FFF;
    font-weight: 600;
    margin: 10px 0;
    font-size: 18px;
  }

  :global(.react-datepicker__day-names > .react-datepicker__day-name) {
    color: #FFF;
  }
}

:global(.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle) {
  fill: $blue;
  stroke: $blue;
  color: $blue;
}

:global(.react-datepicker__day--selected) {
  background-color: #28a745;
  color: white;
}


