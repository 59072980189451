@import "/src/styles/variables";
@import "/src/styles/mixins";

.contentWrap {
  margin-top: 200px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 100px;
}

@media (max-width: 1000px) {
  .contentWrap {
    align-items: center;
  }
}
