@import "./../../../../styles/variables";
@import "./../../../../styles/mixins";

.wrapper {
  margin-top: 10px;
  height: 120px;
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px 10px 0px $shadowColor;
  border-radius: 15px;
  cursor: pointer;

  &:hover {
    .content {
      transition: $defaultTransition;
      transform: scale(1.1);
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: $defaultTransition;
  font-size: 14px;
  text-align: center;
}
