@import "/src/styles/variables";
@import "/src/styles/mixins";

.pagination {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0px;
  margin-top: 10px;

  li {
    list-style: none;
  }
}

.paginationItem {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ddd;
  color: #007bff;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
}

.paginationItem:hover {
  background-color: #007bff;
  color: #fff;
}

.paginationItem.selected {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.paginationItem.disabled {
  cursor: not-allowed;
  color: #ccc;
  border-color: #ddd;
}

.paginationItem.dots {
  cursor: default;
  color: #666;
}

@media (max-width: $mobileScreen) {
  .paginationItem {
    padding: 0px;
  }
}
