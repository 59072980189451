@import "./../../../../../styles/variables";

.wrapper {
  background-color: white;
  height: auto;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 10px;
  bottom: 0;
  margin-bottom: 100px;
  box-shadow: 3px 3px 10px 0px $grey;

  .header {
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: 16px;
    color: black;
    font-weight: 600;
  }

  ul {
    list-style: none;
    padding-left: 10px;
    margin-top: 10px;
    width: fit-content;
  }

  li {
    cursor: pointer;
    position: relative;
    padding-left: 10px;
  }

  li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: $blue;
  }

  li:hover {
    color: $blue;
  }

  img {
    right: 0;
    cursor: pointer;
  }
}
