.additionalPurchasesWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 20px;
}

.additionalPurchasesListWrapper {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;

  p {
    border: 1px solid black;
    padding: 2px 5px;
    font-weight: 400;
  }
}
