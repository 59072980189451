@import "./../../../styles/variables";
@import "./../../../styles/mixins";

.planWrapper {
  border: 1px solid grey;
  padding-bottom: 20px;
  padding-top: 10px;

  ul {
    list-style: none;
    padding: 10px 40px 20px 40px;
  }
}

.planName {
  font-weight: 700;
  color: black !important;
}

.actionButton {
  background-color: white;
  border: none;
  cursor: pointer;
  border: 1px solid $grey;
  border-radius: 5px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonsWrapper {
  display: flex;
  width: 100%;
  column-gap: 10px;
  padding: 0px 40px;
  box-sizing: border-box;
}

.addSubmissions {
  border: 1px solid rgb(184, 184, 184);
  background-color: rgb(243, 243, 243);
  width: 30px;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
}

.additionalPurchases {
  font-weight: 600;
  margin-top: 10px;
}

.purchaseItem {
  display: flex;
  column-gap: 10px;
  margin-bottom: 5px;

  button {
    border: 1px solid rgb(195, 195, 195);
    background-color: white;
    border-radius: 5px;
    cursor: pointer;
  }
}

@media (max-width: 310px) {
  .editButton {
    margin-left: 0px;
  }
}
