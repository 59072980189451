@import "./../../../styles/variables";

.outerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  min-height: 100vh;
  box-sizing: border-box;
}
