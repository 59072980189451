@import "/src/styles/variables";

.outerWrapper {
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid $blue;
  box-shadow: 3px 3px 10px 0px $shadowColor;
  height: 45px;
  padding: 0px 5px;
  border-radius: $defaultBorderRadius;
  font-size: 16px;
}

.wrapper {
  display: flex;
  align-items: center;

  select {
    background-color: white;
    color: black;
    border-radius: 0px;
  }
}

.hours {
  text-align: center;
  height: 30px;
  padding: 0px 5px;
  font-size: 16px;
  border: none;
  border-left: 1px solid $grey;
  border-right: 1px solid $grey;

  &:focus {
    outline: none;
  }
}

.minutes {
  text-align: center;
  height: 30px;
  padding: 0px 5px;
  font-size: 16px;
  border: none;
  border-right: 1px solid $grey;

  &:focus {
    outline: none;
  }
}

.ampm {
  text-align: center;
  height: 30px;
  padding: 0px 5px;
  font-size: 16px;
  border: none;
  border-right: 1px solid $grey;

  &:focus {
    outline: none;
  }
}
