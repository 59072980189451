@import "/src/styles/variables";
@import "/src/styles/mixins";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.reversedWrapper {
  flex-direction: row-reverse;
}

.iconWrap {
  background-color: $blue;
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;

  img {
    max-width: 36px;
  }
}

.title {
  font-size: 52px;
  line-height: 52px;
  font-weight: 900;
  max-width: 500px;
}

.divider {
  border-bottom: 4px solid $blue;
  margin-top: 10px;
  margin-bottom: 20px;
}

.description {
  max-width: 490px;
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
}

.imgWrap {
  img {
    max-width: 550px;
  }
}

@media (max-width: 1000px) {
  .wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: $mobileScreen) {
  .title {
    font-size: 34px;
    line-height: 40px;
  }
  .imgWrap {
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}
