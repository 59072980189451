@import "/src/styles/variables";

.searchWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: $mobileScreen) {
  .searchWrapper {
    flex-direction: column;
    align-items: center;
    row-gap: 20px;

    button {
      margin-top: 10px;
    }

    input {
      max-width: unset;
    }
  }
}
