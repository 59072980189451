@import "./../../../../styles/variables";
@import "./../../../../styles/mixins";

.outerWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 370px;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 0px 10px;
  h1 {
    margin-bottom: 0px;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  margin-top: 20px;
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;

  @include customScrollBar;
}

.colorPicker {
  width: 50px;
  height: 50px;
  border: none;
  cursor: pointer;
}
